import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { I18nProvider } from '@/metronic-dashboard/i18n/i18nProvider';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from '@/metronic-dashboard/context/UserContext';
import { CartProvider } from './context/CartContext';
import IpRedirectWrapper from '@/utils/IpRedirectWrapper'

import { convertNumsToFa } from '@/utils/numLangChanger'
import { isDotIr } from '@/utils/isDotIr';

import Home from '@/pages/home/Home';
import Courses from '@/pages/courses/Courses';
import CourseView from '@/pages/courses/CourseView';
import OnTheLine from '@/pages/on-the-line/OnTheLine';
import OnTheLineView from '@/pages/on-the-line/OnTheLineView';
import Albums from '@/pages/albums/Albums';
import AlbumView from '@/pages/albums/AlbumView';
import Reading from '@/pages/experience-it/Reading';
import Writing from '@/pages/experience-it/Writing';
import Listening from '@/pages/experience-it/Listening';
import Meditation from '@/pages/experience-it/Meditation';
import Podcast from '@/pages/experience-it/Podcast';
import ContactUs from '@/pages/others/ContactUs';
import Donation from '@/pages/others/Donation';
import DonationPaypal from '@/pages/others/DonationPaypal';
import Terms from '@/pages/others/Terms';
import AboutUs from '@/pages/others/AboutUs';
import MakeAppointment from '@/pages/others/MakeAppointment';
import ShoppingCart from '@/pages/ShoppingCart';

import MuChat from '@/components/footer/MuChat';

import { AuthPage } from '@/metronic-dashboard/pages/auth/AuthPage';
import { DashboardPage } from '@/metronic-dashboard/pages/dashboard/DashboardPage';

import OrderResult from '@/pages/OrderResult'
import OrderResultZarin from './pages/OrderResultZarin';

import NotFound from '@/pages/NotFound'


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


const MaintenancePage = () => (
  <div className="min-h-screen flex items-center justify-center bg-gray-100">
    <div className="text-center p-8 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-4">Under Maintenance</h1>
      <p className="text-gray-600">
        ضمن پوزش، در حال بروز رسانی هستیم.</p>
      <p>ساعت شروع فعالیت وب سایت: 16:00 جمعه 9 آذر 1403</p>
    </div>
  </div>
);

// You can easily toggle this boolean
const MAINTENANCE_MODE = false;
// Custom hook to handle route persistence

const useRoutePersistence = () => {
  const location = useLocation();
  const navigate = useNavigate();


  // Save route on location change
  useEffect(() => {
    sessionStorage.setItem('lastRoute', location.pathname + location.search);
  }, [location]);

  // Check for saved route on initial load
  useEffect(() => {
    const handleInitialRoute = () => {
      const lastRoute = sessionStorage.getItem('lastRoute');
      if (lastRoute && window.location.pathname === '/') {
        navigate(lastRoute);
      }
    };

    handleInitialRoute();
  }, [navigate]);

  return location;
};

function App() {
  const location = useRoutePersistence();
  const { pathname } = useLocation();
  const [showChat, setShowChat] = useState(false);

  const chatEnabledRoutes = [
    '/test',
    '/reading',
    '/writing',
    '/listening',
    '/meditation',
    '/podcast',
    '/terms',
    '/about-us',
    '/contact-us',
    // Add more routes as needed
  ];

  useEffect(() => {
    // Check if current route should show chat
    const shouldShow = chatEnabledRoutes.some(route =>
      pathname === route || pathname.startsWith(route + '/')
    );
    setShowChat(shouldShow);

    // This ensures chat is removed when navigating away
    return () => {
      if (!shouldShow) {
        setShowChat(false);
      }
    };
  }, [pathname]);


  useEffect(() => {
    convertNumsToFa();
  }, [location]);

  return (
    <I18nProvider>
      <UserProvider>
        <CartProvider>
          <div className="App">
            <IpRedirectWrapper>
              <ScrollToTop />
              <Routes>
                <Route path="/test" element={
                  <div>
                    <h1>Home Page</h1>
                    <p>If you see this, React is working!</p>
                  </div>
                } />

                <Route path="/" element={MAINTENANCE_MODE ? <MaintenancePage /> : <Home />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/course/:id" element={<CourseView />} />
                <Route path="/live-meeting" element={<OnTheLine />} />
                <Route path="/live-meeting/:id" element={<OnTheLineView />} />
                <Route path="/albums" element={<Albums />} />
                <Route path="/album/:id" element={<AlbumView />} />
                <Route path="/reading" element={<Reading />} />
                <Route path="/writing" element={<Writing />} />
                <Route path="/listening" element={<Listening />} />
                <Route path="/meditation" element={<Meditation />} />
                <Route path="/podcast" element={<Podcast />} />
                <Route path="/donation" element={isDotIr ? <Donation /> : <DonationPaypal />} />
                <Route path="/donate/payment/success" element={isDotIr ? <Donation /> : <DonationPaypal />} />
                <Route path="/donate/payment/cancel" element={isDotIr ? <Donation canceledOrder /> : <DonationPaypal canceledOrder />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/consultation" element={<MakeAppointment />} />

                <Route path="/auth/*" element={<AuthPage />} />
                <Route path="/dashboard/*" element={<DashboardPage />} />

                <Route path="/shopping-cart" element={<ShoppingCart />} />
                <Route path="/payment/result" element={isDotIr ? <OrderResultZarin /> : <OrderResult />} />

                {/* <Route path="/payment/result" element={<OrderResult />} /> */}

                <Route path="/order/payment/success" element={<OrderResult />} />
                <Route path="/order/payment/cancel" element={<OrderResult canceledOrder />} />

                <Route path="*" element={<NotFound />} />
              </Routes>
              {showChat && <MuChat key={pathname} />}
              <ToastContainer rtl />
            </IpRedirectWrapper>
          </div>
        </CartProvider>
      </UserProvider>
    </I18nProvider>
  );
}

export default App;